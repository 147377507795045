import React from "react";
import messengerClone from "../../images/messenger-clone.png";
import noteBook from "../../images/notebook.png";
import cryptoPunkI from "../../images/CryptoPunk.png";
import uberI from "../../images/uber.png";
import chatbotI from "../../images/chatbot.jpg";
import websiteI from "../../images/website.jpg";
import textutlisI from "../../images/textutlis.jpg";
import todoI from "../../images/todolist.jpg";
import { Carousel } from "react-bootstrap";
import "./project.scss";
import Card from "./Card";

const Project = (props) => {
  const messenger_clone = [
    { dataname: "Messenger Clone - secure way to chat" },
    {
      dataname: "Stack:",
      dataname1: "Next js 13, React, Next Auth , Tailwind",
    },
    { dataname: "Database:", dataname1: "Prisma, Mongo Db" },
    { dataname: "Other:", dataname1: "Pusher, Cloudinary" },
    {
      dataname: "Visit Live Website",
      href: "https://messenger-clone-flame.vercel.app/login",
    },
  ];
  const notebook = [
    { dataname: "Secure and free notebook" },
    { dataname: "Access your note anytime" },
    { dataname: "Stack:", dataname1: "MERN" },
    {
      dataname: "Visit Live Website",
      href: "https://yournotebook-secure.netlify.app/",
    },
  ];
  const cryptoPunk = [
    { dataname: "CryptoPunk- Clone" },
    { dataname: "React Js" },
    { dataname: "Web 3.0" },
  ];
  const uberClone = [
    { dataname: "UberClone" },
    { dataname: "Google Authentication" },
    { dataname: "Next Js, Styled Components" },
    { dataname: "FireBase" },
  ];
  const chatBot = [
    { dataname: "Quick Covid-19 check tool" },
    { dataname: "Offline" },
    { dataname: "Text-to-speech conversion" },
    { dataname: "Language:", dataname1: "Python" },
    { dataname: "Packages:", dataname1: "Tensorflow, NLTK, Tflearn, Tkinter" },
  ];
  const website = [
    { dataname: "Static Responsive Website" },
    { dataname: "Typing Effect" },
    { dataname: "Carousel" },
    { dataname: "Animated Bars & Buttons" },
    { dataname: "Messages stored in Spreadsheet" },
    { dataname: "Email Notification for sent message" },
    { dataname: "Languages:", dataname1: "Html, CSS, Javascript" },
    {
      dataname: "Visit Live Website",
      href: "https://namus09.github.io/PersonalWebsite/",
    },
  ];
  const textUtlis = [
    { dataname: "Responsive Website" },
    { dataname: "Free to use" },
    { dataname: "Two Modes : Light & Dark" },
    { dataname: "Copy text, Remove extra spaces & so on" },
    { dataname: "Word counter & Character counter" },
    { dataname: "Displays time to read the text" },
    { dataname: "Languages:", dataname1: "React JS, Bootstrap" },
    { dataname: "Visit Live Website", href: "https://textutlis.netlify.app" },
  ];
  const todoApp = [
    { dataname: " Simple Todo List App" },
    { dataname: "Write your tasks to do." },
    { dataname: "Click to delete the task after completion." },
    { dataname: "Framework:", dataname1: "React Native" },
  ];
  return (
    <section className="d-flex align-items-center" id="project">
      <div className="container-fluid nav-bg">
        <Carousel interval={4000}>
          <Carousel.Item>
            <Card
              mode={props.mode}
              text="Messenge Clone"
              imgsrc={messengerClone}
              data={messenger_clone}
            />
          </Carousel.Item>
          <Carousel.Item>
            <Card
              mode={props.mode}
              text="YourNoteBook"
              imgsrc={noteBook}
              data={notebook}
            />
          </Carousel.Item>
          <Carousel.Item>
            <Card
              mode={props.mode}
              text="Crypto Punk"
              imgsrc={cryptoPunkI}
              data={cryptoPunk}
            />
          </Carousel.Item>
          <Carousel.Item>
            <Card
              mode={props.mode}
              text="Uber Clone"
              imgsrc={uberI}
              data={uberClone}
            />
          </Carousel.Item>
          <Carousel.Item>
            <Card
              mode={props.mode}
              text="Corona Chatbot"
              imgsrc={chatbotI}
              data={chatBot}
            />
          </Carousel.Item>
          <Carousel.Item>
            <Card
              mode={props.mode}
              text="Personal Website"
              imgsrc={websiteI}
              data={website}
            />
          </Carousel.Item>
          <Carousel.Item>
            <Card
              mode={props.mode}
              text="TextUtlis"
              imgsrc={textutlisI}
              data={textUtlis}
            />
          </Carousel.Item>
          <Carousel.Item>
            <Card
              mode={props.mode}
              text="Todo-List App"
              imgsrc={todoI}
              data={todoApp}
            />
          </Carousel.Item>
        </Carousel>
      </div>
    </section>
  );
};

export default Project;
