import React from 'react'
import image from "../../images/dp.png";
import Circularbar from '../CircularBar/Circularbar';
import "./about.scss"


const About = (props) => {
  const Sdata = [
    { values :60, title: "react js"},
    { values :40, title: "next js"},
    { values :40, title: "react native"},
    { values :30, title: "node js"},
    { values :20, title: "express js"},
    { values :50, title: "mongo db"},
    { values :30, title: "FireBase"},
    { values :60, title: "git github"},
    { values :80, title: "bootstrap"},
    { values :50, title: "taliwind"},
    { values :80, title: "javaScript"},
    { values :85, title: "CSS"},
    { values :90, title: "html"}
    ]
    return (
        <>
          <section id="about" className="d-flex align-items-center">
          <div className="container-fluid nav-bg mt-md-3">
            <div className="row">
              <div className="col pt-5 pt-lg-0 ms-3 ms-md-5 order-1 order-lg-2">
                <div className="row d-flex justify-content-center mb-2">
                  <img className="rounded-circle" src={image} style={{height:'200px',width:'200px', border:'5px solid #3498db', marginTop:'-20px', backgroundColor:'#fff'}} alt="about img" />
                  <h1 className="text-center fw-bolder" style={{color:"#3498db"}}>About Me</h1>
                  <div className="line w-100 mb-2 me-3" style={{backgroundColor:'#3498db', height:'3px'}}></div>
                </div>
                <div className="row fw-bolder fs-5 justify-content-center" style={{color:props.mode === 'light'?'black':'white'}}>
                  <ul className="col-md-6 col-xs-auto" style={{listStyleType: 'none'}}>
                    <li><span>Name :</span> Suman Shrestha</li>
                    <li><span>Religion :</span> Hinduism</li>
                    <li><span>Home :</span> Lalitpur-44600, Nepal</li>
                    <li><span>Education :</span> B.E. C.S.E - IV year</li>
                    <li><span>University :</span> Anna University, India</li>
                    <li><span>Work Preference :</span> Home or Office</li>
                  </ul>
                  <ul className="col-md-6 col-xs-auto" style={{listStyleType: 'none'}}>
                    <li><span>Language :</span> English, Nepali, Hindi</li>
                    <li><span>Birth Year :</span> 2001</li>
                    <li><span>Passion :</span> Programming</li>
                    <li className="my-4 ms-5 mt-5"><a type="button" className="btn-get-started" href="SumanShrestha-Resume.pdf" download="SumanShrestha-Resume.pdf">Download Resume</a></li>
                  </ul>
                </div> 
              </div>
              <div className="col ms-auto ms-md-5 order-2 order-lg-2" style={{marginTop:"-2%", marginRight:"1%"}}>
                <h1 className="text-center fw-bolder mb-2" style={{color:"#3498db"}}>My Skills</h1>
                <div className="line w-100 mb-2" style={{backgroundColor:'#3498db', height:'3px'}}></div>
                <div className="circle row mt-3">
                  {
                    Sdata.map((val, ind) =>{
                      return <Circularbar key={ind} values={val.values} title={val.title}/>
                    })
                  }
                </div>
              </div>
            </div>
          </div>
          </section> 
        </>
    )
}

export default About

