import React from 'react'

const Contactinfo = (props) => {
    return (
        <>
            <div className="row align-items-center ms-md-0 ms-1">
                <div className="col-md-auto col-2">
                <i className={`icon ${props.icons}`} style={{fontSize:"2.5rem", color:'#3498db'}}></i>
                </div>
                <div className="col fw-bold">
                    <div className="head" style={{color:'#3498db'}}>{props.head}</div>
                    <div className="sub-title my-0"><a href={props.href}>{props.subtitle}</a></div>
                </div>                        
            </div>
        </>
    )
}

export default Contactinfo
