import React from 'react'

const Footer = (props) => {
    return (
        <>
            <footer className={`w-100 d-flex navbar-${props.mode} bg-${props.mode}`}>
                <p className="w-100 my-2 text-center" style={{color:props.mode === 'light'?'black':'white'}}>Design & Created By  <strong style={{color:"#3598db"}}> Suman Shrestha</strong> | © 2021 All rights reserved.</p>
            </footer>  
        </>
    )
}

export default Footer
