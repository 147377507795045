import React from 'react'
import { Navbar, Nav, Container } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import web from "../images/logo.svg";
import Mode from './Mode';


const Topbar = (props) => {
    return (
        <>
            <Navbar bg={props.mode} variant={props.mode} expand="lg" collapseOnSelect>
                <Container fluid>
                    <LinkContainer to="/" className="mx-5">
                        <Navbar.Brand>
                            <img src={web} className="img-responsive" alt="navbar img" />
                        </Navbar.Brand>
                    </LinkContainer>
                    <div className=" d-block d-md-none">
                        <Mode toggleMode={props.toggleMode} mode={props.mode} src={props.src} />
                    </div>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-md-auto ps-md-0 ps-5 ms-5">
                            <LinkContainer to="/" activeClassName="menu_active" exact className="bi-house-door-fill nav-link">
                                <Nav.Link> Home</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/project" activeClassName="menu_active" exact className="bi-briefcase-fill nav-link">
                                <Nav.Link> Project</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/about" activeClassName="menu_active" exact className="bi-person-circle nav-link">
                                <Nav.Link> About</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/contact" activeClassName="menu_active" exact className="bi-chat-text-fill nav-link">
                                <Nav.Link> Contact</Nav.Link>
                            </LinkContainer>
                        </Nav>
                    </Navbar.Collapse>
                    <div className=" d-none d-md-block">
                        <Mode toggleMode={props.toggleMode} mode={props.mode} src={props.src} />
                    </div>
                </Container>
            </Navbar>
        </>
    )
}

export default Topbar
