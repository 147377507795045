import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle"
import Home from './components/Home/Home';
import Project from './components/Project/Project';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import Alert from "./components/Alert";
import {Switch, Route, Redirect} from "react-router-dom";
import { useState } from "react";
import Footer from "./components/Footer";
import Topbar from "./components/Topbar";
import light from "./images/light-mode.png";
import dark from "./images/dark-mode.png";

const App = () => {
  const [mode, setMode] = useState("light");
  const [alert, setAlert] = useState(null);

  const showAlert =(message ,type)=>{
    setAlert({
      msg : message,
      type :type
    })
    setTimeout(() => {setAlert(null);}, 1500)
  }

  const toggleMode = ()  =>{
    if(mode === 'light'){
      setMode('dark');
      document.body.style.backgroundColor = "#191b1d";
      showAlert("Dark mode has enabled!","success");
    }
    else{
      setMode('light');
      document.body.style.backgroundColor = "white";
      showAlert("Light mode has enabled!","success")
    }
  }

  return (
    <>
      <Topbar mode={mode} toggleMode={toggleMode} src={mode==='light'?dark: light}/>
      <Alert alert={alert} />
      <Switch>
        <Route exact path="/"><Home mode={mode} /></Route>
        <Route exact path= "/project"><Project mode={mode} /></Route> 
        <Route exact path= "/about"><About mode={mode} /></Route> 
        <Route  exact path= "/contact"><Contact mode={mode}/></Route> 
        <Redirect to="/" />
      </Switch>
      <Footer mode={mode}/>
    </>
  )
}

export default App
