import React from "react";
import Typingtext from "../Typingtext";
import Details from "./Details";
const Card = (props) => {
  return (
    <>
      <div className="row align-items-center">
        <h1 className="ms-md-5 ms-2" style={{ color: "#3598db" }}>
          <Typingtext text={props.text} />
        </h1>
        <div className="col-md py-1">
          <img
            className="d-block img-fluid"
            src={props.imgsrc}
            alt="First slide"
            style={{ backgroundColor: "none" }}
          />
        </div>
        <div className="ptext col-md py-2">
          <div
            className="ms-md-5 ms-4 fw-bold"
            style={{ color: props.mode === "light" ? "black" : "white" }}
          >
            <h1 className="ms-md-3" style={{ color: "#3498db" }}>
              Features
            </h1>
            <ul
              className="text ms-md-4 w-100"
              style={{ listStyleType: "none" }}
            >
              {props.data.map((val, ind) => {
                return (
                  <Details
                    key={ind}
                    dataname={val.dataname}
                    dataname1={val.dataname1}
                    href={val.href}
                  />
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
