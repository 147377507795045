import React from 'react'

function Alert(props) {
    const capitalize = (word) =>{
        const lower = word.toLowerCase();
        return lower.charAt(0).toUpperCase() + lower.slice(1);
    }
    return (
        <>
        <div className="alerts">
            {props.alert && <div className={`alert alert-${props.alert.type} alert-dismissible fade show d-none d-md-block`} role="alert">
                <div className="container text-center">
                    <strong>{capitalize(props.alert.type)}</strong>: {props.alert.msg}
                </div>  
            </div> } 
        </div>
        </>
    )
}

export default Alert