import React from 'react'

const Mode = (props) => {
    return (
        <>
            <div className="modes my-0">
                <div className={`form-check form-switch text-${props.mode === 'light' ? 'dark' : 'light'} mt-2`}>
                    <img src={props.src} className="img-responsive animated me-1" alt="home img" onClick={props.toggleMode} style={{height:"2.5rem", marginTop:"-10px"}}/>
                </div>
            </div>
        </>
    )
}

export default Mode
