import React from 'react'
import { Link} from 'react-router-dom'
import Contactinfo from '../Contactinfo'

const Contact = (props) => {
    
    const handleSubmit = event => {
      var form = document.getElementById("my-form");
      var status = document.getElementById("status");
      var process = document.getElementById("process");
      function success() {
          process.innerHTML = "";
          form.reset();
          status.innerHTML = "Thanks! I will contact you soon.";
          setTimeout(function(){
            status.innerHTML ="";
          },2000)
      }
      function error() {
          process.innerHTML = "";
          status.innerHTML = "Oops! There was a problem.";
          setTimeout(function(){
          status.innerHTML ="";
        },2000)
      }
      process.innerHTML = "Sending...";
      event.preventDefault();
      var data = new FormData(form);
      const url = "https://script.google.com/macros/s/AKfycbwrONjzVnSKgQ2wf7QaPYuCtGBlsP7oEp6DEyhf/exec";
      let method ="POST";
      ajax(method, url, data, success ,error); 
    };
    function ajax(method, url, data, success ,error) {
      var xhr = new XMLHttpRequest();
      xhr.open(method, url);
      xhr.setRequestHeader("Accept", "application/json");
      xhr.onreadystatechange = function () {
        if (xhr.readyState !== XMLHttpRequest.DONE) return;
        if (xhr.status === 200) {
          success(xhr.response, xhr.responseType);
        } else {
          error(xhr.status, xhr.response, xhr.responseType);
        }
      };
      xhr.send(data);
    };
    return (
        <> 
            <section id="about" className="d-flex align-items-center">
            <div className="container-fluid nav-bg" style={{color:props.mode === 'light'?'black':'white'}}>
              <div className="row ms-md-5 me-md-5 ms-auto me-auto">
                <div className="col-md-6 pt-4 pt-lg-0  ms-auto ms-md-5 me-5 order-1 order-lg-2">
                  <div className="row">
                  <p className = " fs-2 fw-bolder " style={{color:"#3498db"}}>WOW 😍😍, Nice to see you here !</p>
                  <p className="fs-4">Feel free to contact me at any time. I am always there to discuss new projects, creative ideas, or opportunities. I'll be greatful to be part of your vision.</p>
                  </div>
                  <div className="row ms-md-0">
                      <Contactinfo icons= "bi-person-fill" head= "Name :" subtitle= "Suman Shrestha"/>
                      <Contactinfo icons= "bi-geo-alt-fill" head= "Location :" subtitle= "Lalitpur, Nepal"/>
                      <Contactinfo icons= "bi-envelope-fill" head= "Email :" href="mailto:shresthasuman839@gmail.com" subtitle= "shresthasuman839@gmail.com"/>
                  </div>
                  <div className="row ms-0">
                    <h1 className=" d-flex ms-md-5 my-3 ms-5" style={{fontSize:'3rem'}}>
                      <p className= "me-3"><Link to={{ pathname: "https://www.facebook.com/suman.stha.25" }} target="_blank" className="bi-facebook"/></p>
                      <p className= "me-3"><Link to={{ pathname: "https://www.instagram.com/namus_suman_25/" }} target="_blank" className="bi-instagram"/></p>
                      <p><Link to={{ pathname: "https://twitter.com/SumanSh24385321" }} target="_blank" className="bi-twitter"/></p>
                    </h1>
                  </div>
                </div>
                <div className="col ms-md-3 order-2 order-lg-2">
                  <div className="row ms-md-3 me-md-3 ms-2 mb-md-1 mb-3">
                    <h1 className="fw-bold" style={{color:'#3498db'}}>Message Me</h1>
                    <form className="row g-3 needs-validation fw-bold" onSubmit={handleSubmit}  id="my-form">
                      <div className="col-md-6 my-0">
                        <label htmlFor="validationCustom01" className="form-label">First name</label>
                        <input type="text" className="form-control" id="validationCustom01" placeholder="First Name " name="First Name" required />
                      </div>
                      <div className="col-md-6 my-0">
                        <label htmlFor="validationCustom02" className="form-label">Last name</label>
                        <input type="text" className="form-control" id="validationCustom02" placeholder="Last Name" name="Last Name" required />
                      </div>
                      <div className="col w-100 my-1">
                        <label htmlFor="validationCustom03" className="form-label">Email</label>
                        <input type="text" className="form-control" id="validationCustom03" placeholder="Enter your email" name="Email" required />
                      </div>
                      <div className="col-md-1 w-100 my-1">
                        <label htmlFor="validationCustom04" className="form-label">Subject</label>
                        <input type="text" className="form-control" id="validationCustom04" placeholder="Subject" name="Subject" required />
                      </div>
                      <div className="mb-3 my-1">
                        <label htmlFor="exampleFormControlTextarea1" className="form-label">Message</label>
                        <textarea className="form-control" id="exampleFormControlTextarea1" placeholder="Enter your message.." rows="3" name="Message"></textarea>
                      </div>
                      <div className="row d-flex align-items-center h-auto">
                        <div className="col-md-auto">
                          <button className="btn btn-primary" type="submit">Send Message</button>
                        </div>
                        <div className="col-md-auto my-md-0 my-1" style={{height:'25px'}} id="process"></div>
                        <div className="col-md-auto h-10" style={{height:'25px'}} id="status"></div>
                      </div>
                  </form>
                  </div>
                </div>
              </div>
            </div>
            </section>
        </>
    )
}

export default Contact
