import React from 'react'

const Details = (props) => {
    return (
        <>
            <li><span>&#8594;</span><a href={props.href} target="_blank" rel="noreferrer">{props.dataname}<p className="ms-5 my-0 w-100">{props.dataname1}</p></a></li>
        </>
    )
}

export default Details
