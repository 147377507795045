import React from 'react'
import { NavLink } from 'react-router-dom';

const Globalbutton = (props) => {
    return (
        <div>
            <NavLink type="button" className="btn-get-started" to={props.destination}>{props.text}</NavLink>
        </div>
    )
}

export default Globalbutton
