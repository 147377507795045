import React from 'react';
import ReactTypingEffect from 'react-typing-effect';

const Typingtext = (props) => {
    return (
        <>
            <ReactTypingEffect 
            text={props.text} 
            speed={300}
            eraseSpeed={100}
            eraseDelay={200}
            typingDelay={100}
            />          
        </>
    )
}

export default Typingtext
