import React from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import AnimatedProgressProvider from "./AnimatedProgressProvider";
import { easeQuadInOut } from 'd3-ease';
import './CircularBar.scss'

const Circularbar = (props) => {
    return (
        <>
        <div className= "circularbar my-3 ms-3">
            <AnimatedProgressProvider
                valueStart={0}
                valueEnd={props.values}
                duration={1.5}
                easingFunction={easeQuadInOut}
            >
                {(value) => {
                    const roundedValue = Math.round(value);
                    return (
                    <CircularProgressbar
                        value={value}
                        text={`${roundedValue}%`}
                        /* This is important to include, because if you're fully managing the
                        animation yourself, you'll want to disable the CSS animation. */
                        styles={buildStyles({ pathTransition: 'none' })}
                    />
                    )
                }}
            </AnimatedProgressProvider>
            <div className="cText text-center my-2 fw-bold text-capitalize">{props.title}</div>  
        </div>
        </>
    )
}

export default Circularbar
