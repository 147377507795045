import React from 'react'
import web1 from "../../images/html.svg";
import web2 from "../../images/css.svg";
import web3 from "../../images/js.svg";
import web4 from "../../images/react.svg";
import web5 from "../../images/nodejs.svg";
import web6 from "../../images/bootstrap.svg";
import Typingtext from '../Typingtext';
import Globalbutton from '../Globalbutton';

const Home = (props) => {
    const text1=["Python Programmer.", "Web-Developer.", "Freelancer.","Enginerring Student."];
    return (
        <>
            <section id="header" className="d-flex align-items-center">
                <div className="container-fluid nav-bg">
                    <div className="row">
                        <div className="col ms-md-5 ps-md-5 ms-4">
                            <div className="row">
                                <div className="info col-md-6 pt-5 pt-md-0 order-2 order-lg-1 d-flex justify-content-center flex-column">
                                    <h2 className="my-3" style={{color:props.mode === 'light'?'black':'white'}}>
                                        Hi there !🖐️🖐️
                                    </h2>
                                    <h1 className="my-3" style={{color:props.mode === 'light'? 'black':'white'}}>
                                        I'm <strong className="brand-name"> Suman Shrestha.</strong>
                                    </h1>
                                    <h2 className="my-3" style={{color:props.mode === 'light'?'black':'white'}}>
                                        And I'm 
                                    </h2>
                                    <h1 style ={{color:'#3598db'}}><Typingtext text={text1} /></h1>
                                    <div className="mt-md-5 mt-4 mb-md-0 mb-5 pb-md-0 pb-4">
                                        <Globalbutton destination="/project" text="Know more"/>
                                    </div>
                                </div>
                                <div className="col-md-6 my-md-0 my-5 pt-md-0 pt-2 ms-md-0 ms-3 order-1 order-lg-2 header-img">
                                    <div className="row w-100">
                                        <div className="col">
                                            <img src={web1} className="img-responsive animated me-4" alt="home img" />
                                            <img src={web2} className="img-responsive animated1 me-4" alt="home img" />
                                            <img src={web3} className="img-responsive animated" alt="home img" />
                                        </div>
                                    </div> 
                                    <div className="row my-5 w-100">
                                        <div className="col">
                                            <img src={web4} className="img-responsive animated1" alt="home img" />
                                            <img src={web5} className="img-responsive animated me-4" alt="home img" />                                      
                                            <img src={web6} className="img-responsive animated1" alt="home img" />
                                        </div>
                                    </div>                                     
                                </div> 
                            </div>
                        </div>
                    </div>
                    <div className="alert alert-warning alert-dismissible fade show d-block d-sm-none fs-10" role="alert">
                        <strong>Note: </strong>Visit from desktop for better experience.
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Home
